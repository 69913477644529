/*
Etelä-Karjalan hyvinvointialue muodostuu Imatran, Lappeenrannan, Lemin, Luumäen, Parikkalan, Rautjärven, Ruokolahden, Savitaipaleen ja Taipalsaaren kunnista.
Etelä-Pohjanmaan hyvinvointialue muodostuu Alajärven, Alavuden, Evijärven, Ilmajoen, Isojoen, Isonkyrön, Karijoen, Kauhajoen, Kauhavan, Kuortaneen, Kurikan, Lappajärven, Lapuan, Seinäjoen, Soinin, Teuvan, Vimpelin ja Ähtärin kunnista.
Etelä-Savon hyvinvointialue muodostuu Enonkosken, Hirvensalmen, Juvan, Kangasniemen, Mikkelin, Mäntyharjun, Pertunmaan, Pieksämäen, Puumalan, Rantasalmen, Savonlinnan ja Sulkavan kunnista.
Itä-Uudenmaan hyvinvointialue muodostuu Askolan, Lapinjärven, Loviisan, Myrskylän, Porvoon, Pukkilan ja Sipoon kunnista.
Kainuun hyvinvointialue muodostuu Hyrynsalmen, Kajaanin, Kuhmon, Paltamon, Puolangan, Ristijärven, Sotkamon ja Suomussalmen kunnista.
Kanta-Hämeen hyvinvointialue muodostuu Forssan, Hattulan, Hausjärven, Humppilan, Hämeenlinnan, Janakkalan, Jokioisten, Lopen, Riihimäen, Tammelan ja Ypäjän kunnista.
Keski-Pohjanmaan hyvinvointialue muodostuu Halsuan, Kannuksen, Kaustisen, Kokkolan, Lestijärven, Perhon, Toholammin ja Vetelin kunnista.
Keski-Suomen hyvinvointialue muodostuu Hankasalmen, Joutsan, Jyväskylän, Jämsän, Kannonkosken, Karstulan, Keuruun, Kinnulan, Kivijärven, Konneveden, Kyyjärven, Laukaan, Luhangan, Multian, Muuramen, Petäjäveden, Pihtiputaan, Saarijärven, Toivakan, Uuraisten, Viitasaaren ja Äänekosken kunnista.
Keski-Uudenmaan hyvinvointialue muodostuu Hyvinkään, Järvenpään, Nurmijärven, Mäntsälän, Tuusulan ja Pornaisten kunnista.
Kymenlaakson hyvinvointialue muodostuu Haminan, Kotkan, Kouvolan, Miehikkälän, Pyhtään ja Virolahden kunnista.
Lapin hyvinvointialue muodostuu Enontekiön, Inarin, Kemin, Kemijärven, Keminmaan, Kittilän, Kolarin, Muonion, Pelkosenniemen, Pellon, Posion, Ranuan, Rovaniemen, Sallan, Savukosken, Simon, Sodankylän, Tervolan, Tornion, Utsjoen ja Ylitornion kunnista.
Länsi-Uudenmaan hyvinvointialue muodostuu Espoon, Hangon, Inkoon, Karkkilan, Kauniaisten, Kirkkonummen, Lohjan, Raaseporin, Siuntion ja Vihdin kunnista.
Pirkanmaan hyvinvointialue muodostuu Akaan, Hämeenkyrön, Ikaalisten, Juupajoen, Kangasalan, Kihniön, Kuhmoisten, Lempäälän, Mänttä-Vilppulan, Nokian, Oriveden, Parkanon, Pirkkalan, Punkalaitumen, Pälkäneen, Ruoveden, Sastamalan, Tampereen, Urjalan, Valkeakosken, Vesilahden, Virtain ja Ylöjärven kunnista.
Pohjanmaan hyvinvointialue muodostuu Kaskisten, Korsnäsin, Kristiinankaupungin, Kruunupyyn, Laihian, Luodon, Maalahden, Mustasaaren, Närpiön, Pedersören, Pietarsaaren, Uusikaarlepyyn, Vaasan ja Vöyrin kunnista.
Pohjois-Karjalan hyvinvointialue muodostuu Heinäveden, Ilomantsin, Joensuun, Juuan, Kiteen, Kontiolahden, Lieksan, Liperin, Nurmeksen, Outokummun, Polvijärven, Rääkkylän ja Tohmajärven kunnista.
Pohjois-Pohjanmaan hyvinvointialue muodostuu Alavieskan, Haapajärven, Haapaveden, Hailuodon, Iin, Kalajoen, Kempeleen, Kuusamon, Kärsämäen, Limingan, Lumijoen, Merijärven, Muhoksen, Nivalan, Oulaisten, Oulun, Pudasjärven, Pyhäjoen, Pyhäjärven, Pyhännän, Raahen, Reisjärven, Sievin, Siikajoen, Siikalatvan, Taivalkosken, Tyrnävän, Utajärven, Vaalan ja Ylivieskan kunnista.
Pohjois-Savon hyvinvointialue muodostuu Iisalmen, Joroisten, Kaavin, Keiteleen, Kiuruveden, Kuopion, Lapinlahden, Leppävirran, Pielaveden, Rautalammin, Rautavaaran, Siilinjärven, Sonkajärven, Suonenjoen, Tervon, Tuusniemen, Varkauden, Vesannon ja Vieremän kunnista.
Päijät-Hämeen hyvinvointialue muodostuu Asikkalan, Hartolan, Heinolan, Hollolan, Iitin, Kärkölän, Lahden, Orimattilan, Padasjoen ja Sysmän kunnista.
Satakunnan hyvinvointialue muodostuu Euran, Eurajoen, Harjavallan, Huittisten, Jämijärven, Kankaanpään, Karvian, Kokemäen, Merikarvian, Nakkilan, Pomarkun, Porin, Rauman, Siikaisten, Säkylän ja Ulvilan kunnista.
Vantaan ja Keravan hyvinvointialue muodostuu Vantaan ja Keravan kaupungeista.
Varsinais - Suomen hyvinvointialue muodostuu Auran, Kaarinan, Kemiönsaaren, Koski Tl: n, Kustavin, Laitilan, Liedon, Loimaan, Marttilan, Maskun, Mynämäen, Naantalin, Nousiaisten, Oripään, Paimion, Paraisten, Pyhärannan, Pöytyän, Raision, Ruskon, Salon, Sauvon, Someron, Taivassalon, Turun, Uusikaupungin ja Vehmaan kunnista.
*/

/*Etelä-Karjalan hyvinvointialue
https://www.ekhva.fi/asiakkaalle/tarvitsetko-apua/voi-hyvin/rokotukset/

Etelä-Pohjanmaan hyvinvointialue
https://www.hyvaep.fi/palvelu/kausirokotukset/

Etelä-Savon hyvinvointialue
https://etelasavonha.fi/asiakkaan-opas/kausirokotus/

Itä-uudenmaan hyvinvointialue
https://itauusimaa.fi/terveys/rokotukset/

Kainuun hyvinvointialue
https://hyvinvointialue.kainuu.fi/kausirokotukset

Kanta-Hämeen hyvinvointialue
https://omahame.fi/rokotusinfo

Keski-Pohjanmaan hyvinvointialue
https://soite.fi/palvelut-ja-yhteys/terveys-ja-sairaanhoitopalvelut/tartuntataudit-ja-rokotukset/kausi-influenssarokotukset/

Keski-Suomen hyvinvointialue
https://www.hyvaks.fi/influenssajakoronarokotukset

Keski-Uudenmaan hyvinvointialue 
https://www.keusote.fi/palveluhakemisto/rokotuspalvelut/

Kymenlaakson hyvinvointialue
https://kymenhva.fi/terveys-ja-sairaanhoito/rokotukset/

Lapin hyvinvointialue
https://lapha.fi/kausirokotukset

Länsi-Uudenmaan hyvinvointialue
https://www.luvn.fi/fi/palvelut/rokotukset

Pirkanmaan hyvinvointialue
https://www.pirha.fi/palvelut/terveyspalvelut/rokotukset-ja-tartuntataudit 

Pohjanmaan hyvinvointialue
https://pohjanmaanhyvinvointi.fi/palvelumme/terveys-ja-sairaanhoitopalvelut/terveyskeskusten-palvelut/rokotukset/

Pohjois-Karjalan hyvinvointialue
https://www.siunsote.fi/rokotukset

Pohjois-Pohjanmaan hyvinvointialue
https://pohde.fi/palvelut/rokotukset/

Pohjois-Savon hyvinvointialue
 https://pshyvinvointialue.fi/influenssarokotukset

Päijät-Hämeen hyvinvointialue
https://paijat-sote.fi/laakariin-ja-hoitoon/sote-keskusten-terveyspalvelut/rokotukset/

Satakunnan hyvinvointialue
https://satakunnanhyvinvointialue.fi/palvelut/terveyspalvelut/rokotukset/

Vantaan ja Keravan hyvinvointialue
https://vakehyva.fi/fi/palveluhakemisto/palvelu/rokotukset#tab-introduction

Varsinais-Suomen hyvinvointialue
 https://www.varha.fi/fi/palvelut/rokotuspalvelut

Helsinki 
https://rokotukset.hel.fi/*/


export const Places = [
    { name: "Helsinki", municipalities: ["Helsinki"], link: "https://rokotukset.hel.fi/" },
    { name: "Etelä-Karjalan hyvinvointialue", municipalities: ["Imatra", "Lappeenranta", "Lemi", "Luumäki", "Parikkala", "Rautjärvi", "Ruokolahti", "Savitaipale", "Taipalsaari"], link: "https://www.ekhva.fi/asiakkaalle/tarvitsetko-apua/voi-hyvin/rokotukset/" },
    { name: "Etelä-Pohjanmaan hyvinvointialue", municipalities: ["Alajärvi", "Alavus", "Evijärvi", "Ilmajoki", "Isojoki", "Isonkyrö", "Karijoki", "Kauhajoki", "Kauhava", "Kuortane", "Kurikka", "Lappajärvi", "Lapua", "Seinäjoki", "Soini", "Teuva", "Vimpeli", "Ähtäri"], link: "https://www.hyvaep.fi/palvelu/kausirokotukset/" },
    { name: "Etelä-Savon hyvinvointialue", municipalities: ["Enonkoski", "Hirvensalmi", "Juva", "Kangasniemi", "Mikkeli", "Mäntyharju", "Pertunmaa", "Pieksämäki", "Puumala", "Rantasalmi", "Savonlinna", "Sulkava"], link: "https://etelasavonha.fi/asiakkaan-opas/kausirokotus/" },
    { name: "Itä-Uudenmaan hyvinvointialue", municipalities: ["Askola", "Lapinjärvi", "Loviisa", "Myrskylä", "Porvoo", "Pukkila", "Sipoo"], link: "https://itauusimaa.fi/terveys/rokotukset/influenssarokote/" },
    { name: "Kainuun hyvinvointialue", municipalities: ["Hyrynsalmi", "Kajaani", "Kuhmo", "Paltamo", "Puolanka", "Ristijärvi", "Sotkamo", "Suomussalmi"], link: "https://hyvinvointialue.kainuu.fi/kausirokotukset" },
    { name: "Kanta-Hämeen hyvinvointialue", municipalities: ["Forssa", "Hattula", "Hausjärvi", "Humppila", "Hämeenlinna", "Janakkala", "Jokioinen", "Loppi", "Riihimäki", "Tammela", "Ypäjä"], link: "https://omahame.fi/rokotusinfo" },
    { name: "Keski-Pohjanmaan hyvinvointialue", municipalities: ["Halsua", "Kannus", "Kaustinen", "Kokkola", "Lestijärvi", "Perho", "Toholampi", "Veteli"], link: "https://soite.fi/palvelut-ja-yhteys/terveys-ja-sairaanhoitopalvelut/tartuntataudit-ja-rokotukset/kausi-influenssarokotukset/" },
    { name: "Keski-Suomen hyvinvointialue", municipalities: ["Hankasalmi", "Joutsa", "Jyväskylä", "Jämsä", "Kannonkoski", "Karstula", "Keuruu", "Kinnula", "Kivijärvi", "Konnevesi", "Kyyjärvi", "Laukaa", "Luhanka", "Multia", "Muurame", "Petäjävesi", "Pihtipudas", "Saarijärvi", "Toivakka", "Uurainen", "Viitasaari", "Äänekoski"], link: "https://soite.fi/palvelut-ja-yhteys/terveys-ja-sairaanhoitopalvelut/tartuntataudit-ja-rokotukset/kausi-influenssarokotukset/" },
    { name: "Keski-Uudenmaan hyvinvointialue", municipalities: ["Hyvinkää", "Järvenpää", "Nurmijärvi", "Mäntsälä", "Tuusula", "Pornainen"], link: "https://www.keusote.fi/palveluhakemisto/rokotuspalvelut/" },
    { name: "Kymenlaakson hyvinvointialue", municipalities: ["Hamina", "Kotka", "Kouvola", "Miehikkälä", "Pyhtää", "Virolahti"], link: "https://kymenhva.fi/palvelut-ja-ajanvaraus/terveydenhuolto/rokotukset/influenssarokotukset/" },
    { name: "Lapin hyvinvointialue", municipalities: ["Enontekiö", "Inari", "Kemi", "Kemijärvi", "Keminmaa", "Kittilä", "Kolari", "Muonio", "Pelkosenniemi", "Pello", "Posio", "Ranua", "Rovaniemi", "Salla", "Savukoski", "Simo", "Sodankylä", "Tervola", "Tornio", "Utsjoki", "Ylitornio"], link: "https://lapha.fi/kausirokotukset" },
    { name: "Länsi-Uudenmaan hyvinvointialue", municipalities: ["Espoo", "Hanko", "Inkoo", "Karkkila", "Kauniainen", "Kirkkonummi", "Lohja", "Raasepori", "Siuntio", "Vihti"], link: "https://www.luvn.fi/fi/kausirokotukset-lansi-uudenmaan-hyvinvointialueella" },
    { name: "Pirkanmaan hyvinvointialue", municipalities: ["Akaa", "Hämeenkyrö", "Ikaalinen", "Juupajoki", "Kangasala", "Kihniö", "Kuhmoinen", "Lempäälä", "Mänttä-Vilppula", "Nokia", "Orivesi", "Parkano", "Pirkkala", "Punkalaidun", "Pälkäne", "Ruovesi", "Sastamala", "Tampere", "Urjala", "Valkeakoski", "Vesilahti", "Virrat", "Ylöjärvi"], link: "https://www.pirha.fi/palvelut/terveyspalvelut/rokotukset/influenssarokotukset" },
    { name: "Pohjanmaan hyvinvointialue", municipalities: ["Kaskinen", "Korsnäs", "Kristiinankaupunki", "Kruunupyy", "Laihia", "Luoto", "Maalahti", "Mustasaari", "Närpiö", "Pedersöre", "Pietarsaari", "Uusikaarlepyy", "Vaasa", "Vöyri"], link: "https://pohjanmaanhyvinvointi.fi/palvelumme/terveys-ja-sairaanhoitopalvelut/terveyskeskusten-palvelut/rokotukset/influenssarokotukset/" },
    { name: "Pohjois-Karjalan hyvinvointialue", municipalities: ["Heinävesi", "Ilomantsi", "Joensuu", "Juua", "Kitee", "Kontiolahti", "Lieksa", "Liperi", "Nurmeks", "Outokumpu", "Polvijärvi", "Rääkkylä", "Tohmajärvi"], link: "https://www.siunsote.fi/influenssarokotukset" },
    { name: "Pohjois-Pohjanmaan hyvinvointialue", municipalities: ["Alavieska", "Haapajärvi", "Haapavesi", "Hailuoto", "Ii", "Kalajoki", "Kempele", "Kuusamo", "Kärsämäki", "Liminka", "Lumijoki", "Merijäri", "Muhos", "Nivala", "Oulainen", "Oulu", "Pudasjärvi", "Pyhäjoki", "Pyhäjärvi", "Pyhäntä", "Raahe", "Reisjärvi", "Sievi", "Siikajoki", "Siikalatva", "Taivalkoski", "Tyrnävä", "Utajärvi", "Vaala", "Ylivieska"], link: "https://pohde.fi/palvelumme/rokotukset/tietoa-rokotuksista/#influenssarokotukset" },
    { name: "Pohjois-Savon hyvinvointialue", municipalities: ["Iisalmi", "Joroinen", "Kaavi", "Keitele", "Kiuruvesi", "Kuopio", "Lapinlahti", "Leppävirta", "Pielavesi", "Rautalammi", "Rautavaara", "Siilinjärvi", "Sonkajärvi", "Suonenjoki", "Tervo", "Tuusniemi", "Varkaus", "Vesanto", "Vieremä"], link: "https://pshyvinvointialue.fi/influenssarokotukset" },
    { name: "Päijät-Hämeen hyvinvointialue", municipalities: ["Asikkala", "Hartola", "Heinola", "Hollola", "Iitti", "Kärkölä", "Lahti", "Orimattila", "Padasjoki", "Sysmä"], link: "https://paijatha.fi/palvelut-ja-hoitoon-hakeutuminen/terveyspalvelut/rokotukset/#influenssarokotukset" },
    { name: "Satakunnan hyvinvointialue", municipalities: ["Eura", "Eurajoki", "Harjavalta", "Huittinen", "Jämijärvi", "Kankaanpää", "Karvia", "Kokemäki", "Merikarvia", "Nakkila", "Pomarkku", "Pori", "Rauma", "Siikainen", "Säkylä", "Ulvila"], link: "https://satakunnanhyvinvointialue.fi/palvelut/terveyspalvelut/rokotukset/influenssarokotukset/" },
    { name: "Vantaan ja Keravan hyvinvointialue", municipalities: ["Vantaa", "Kerava"], link: "https://vakehyva.fi/fi/ajankohtaista/ajankohtaista/kausirokotukset-vantaan-ja-keravan-hyvinvointialueella" },
    { name: "Varsinais-Suomen hyvinvointialue", municipalities: ["Aura", "Kaarina", "Kemiönsaari", "Koski Tl", "Kustavi", "Laitila", "Lieto", "Loimaa", "Marttila", "Masku", "Mynämäki", "Naantali", "Nousiainen", "Oripää", "Paimio", "Parainen", "Pyhäranta", "Pöytyä", "Raisio", "Rusko", "Salo", "Sauvo", "Somero", "Taivassalo", "Turku", "Uusikaupunki", "Vehmaa"], link: "https://www.varha.fi/fi/palvelut/rokotuspalvelut" },
];
export const municipalities = Places.flatMap(place => place.municipalities);
export const municipalitiesWithParent = Places.flatMap(place => place.municipalities.map(municipality => ({ name: municipality, parent: place.name, link: place.link })));
